<template>
  <div>
    <b-row align-h="center" v-if="dataLoading">
      <b-spinner
        class="mt-5"
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>

    <b-row v-else class="" align-h="center">
      <b-col md="7">
        <b-row>
          <b-col md="12">
            <b-button
              variant="outline-secondary"
              class="btn-icon"
              @click="$router.go(-1)"
            >
              <feather-icon icon="ArrowLeftIcon" />
              Back
            </b-button>
          </b-col>
        </b-row>
        <b-card class="mt-1">
          <h3 class="mb-0">Student Information</h3>
          <hr />
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Name"
                invalid-feedback="Student name is required."
              >
                <b-form-input
                  placeholder="Enter name."
                  ref="name"
                  v-model.trim="myObj.name"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Father name"
                invalid-feedback="Father name is required."
              >
                <b-form-input
                  ref="fname"
                  placeholder="Enter father name."
                  v-model.trim="myObj.father_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Gender"
                invalid-feedback="Gender is required."
                ref="gender"
              >
                <v-select
                  v-model="myObj.gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genders"
                  :clearable="false"
                  placeholder="Select gender"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date of Birth"
                invalid-feedback="Date of birth is required."
                ref="dob"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.date_of_birth"
                  class="form-control"
                  placeholder="Select date of birth."
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Religion"
                invalid-feedback="Religion is required."
                ref="religion"
              >
                <v-select
                  v-model="myObj.religion"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="religions"
                  :clearable="false"
                  placeholder="Select religion"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date of Admission"
                invalid-feedback="Date of Admission is required."
                ref="doa"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.date_of_admission"
                  class="form-control"
                  placeholder="Select date of admission."
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Last Institute"
                invalid-feedback="Last Institute is required."
                ref="last"
              >
                <b-form-input
                  placeholder="Enter last institute."
                  v-model.trim="myObj.last_institute"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Place of birth"
                invalid-feedback="Place of birth is required."
                ref="pob"
              >
                <b-form-input
                  placeholder="Enter place of birth"
                  v-model.trim="myObj.place_of_birth"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Mother Tongue"
                invalid-feedback="Mother Tongue is required."
                ref="tongue"
              >
                <b-form-input
                  placeholder="Enter mother tongue"
                  v-model.trim="myObj.mother_tongue"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nationality"
                invalid-feedback="Nationality is required."
                ref="nationality"
              >
                <b-form-input
                  placeholder="Enter nationality"
                  v-model.trim="myObj.nationality"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Current Address"
                invalid-feedback="Address is required."
                ref="address"
              >
                <b-form-input
                  placeholder="Enter current address."
                  v-model.trim="myObj.address_current"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="Father Occupation"
                invalid-feedback="Occupation is required."
                ref="occ"
              >
                <b-form-input
                  v-model.trim="myObj.father_occupation"
                  placeholder="Enter Occupation"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Father CNIC"
                invalid-feedback="CNIC is required."
                ref="fCnic"
              >
                <b-form-input
                  v-model.trim="myObj.father_cnic"
                  placeholder="Enter CNIC number"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email"
                invalid-feedback="Email is required."
                ref="fEmail"
              >
                <b-form-input
                  v-model.trim="myObj.father_email"
                  placeholder="Enter Email"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Father Employer (Firm)"
                invalid-feedback="Employer is required."
                ref="emp"
              >
                <b-form-input
                  v-model.trim="myObj.father_employer"
                  placeholder="Enter Employer"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Office Address"
                invalid-feedback="Office Address is required."
                ref="office"
              >
                <b-form-input
                  placeholder="Enter office address"
                  v-model.trim="myObj.office_address"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Father's Contact"
                invalid-feedback="Please enter correct contact number."
              >
                <vue-tel-input
                  v-model="myObj.contact_father"
                  :inputOptions="vueTelOptions"
                  :dropdownOptions="dropOptions"
                  @input="CheckFContact"
                ></vue-tel-input>
                <small class="mr-1 text-danger" v-if="fphoneerror"
                  >Phone number is required.</small
                >
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Mother's Contact"
                invalid-feedback="Please enter correct phone number."
              >
                <vue-tel-input
                  :inputOptions="vueTelOptions"
                  @input="CheckMContact"
                  :dropdownOptions="dropOptions"
                  v-model="myObj.contact_mother"
                ></vue-tel-input>
                <small class="mr-1 text-danger" v-if="mphoneerror"
                  >Phone number is required.</small
                >
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Receipt No"
                invalid-feedback="Receipt No is required."
                ref="recNo"
              >
                <b-form-input
                  placeholder="Enter receipt no"
                  v-model="myObj.receiptNo"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-row>
          <b-col md="12">
            <b-button
              block
              variant="success"
              :disabled="request"
              @click="updateStudent()"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save & Print</span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    sID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      genders: ["Male", "Female"],
      religions: ["ISLAM", "CHRISTIANITY", "HINDUISM", "BUDDHISM", "SIKHISM"],
      myObj: {},
      dataLoading: false,
      request: false,
      fphoneerror: false,
      mphoneerror: false,
      vueTelOptions: { placeholder: "Enter contact number" },
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
      reportDomain: this.$store.state.report.replace(/_/g, ""),
    };
  },
  created() {
    // console.log(this.sID);
    if (this.sID) this.LoadStudent();
    else {
      this.$router.replace({ name: "student-enrolled" }).then(() => {
        this.$bvToast.toast("No record found!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      });
    }
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.father_name == "" || this.myObj.father_name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGender() {
      var elem = this.$refs["gender"];
      if (!this.myObj.gender) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDob() {
      var elem = this.$refs["dob"];
      if (!this.myObj.date_of_birth) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckReligion() {
      var elem = this.$refs["religion"];
      if (!this.myObj.religion) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["doa"];
      if (!this.myObj.date_of_admission) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckLast() {
      var elem = this.$refs["last"];
      if (!this.myObj.last_institute) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPlace() {
      var elem = this.$refs["pob"];
      if (!this.myObj.place_of_birth) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTongue() {
      var elem = this.$refs["tongue"];
      if (!this.myObj.mother_tongue) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckNationality() {
      var elem = this.$refs["nationality"];
      if (!this.myObj.nationality) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAddress() {
      var elem = this.$refs["address"];
      if (!this.myObj.address_current) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckOccup() {
      var elem = this.$refs["occ"];
      if (!this.myObj.father_occupation) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCnic() {
      var elem = this.$refs["fCnic"];
      if (!this.myObj.father_cnic) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmail() {
      var elem = this.$refs["fEmail"];
      if (!this.myObj.father_email) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmp() {
      var elem = this.$refs["emp"];
      if (!this.myObj.father_employer) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckOffice() {
      var elem = this.$refs["office"];
      if (!this.myObj.office_address) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckRec() {
      var elem = this.$refs["recNo"];
      let x = parseInt(this.myObj.receiptNo);
      if (isNaN(x) || x <= 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFContact(s, e) {
      // console.log(this.myObj.contact_father);
      if (e.valid) {
        this.fphoneerror = false;
        this.myObj.contact_father = e.number;
        // console.log(this.myObj.contact_father);
      } else {
        this.fphoneerror = true;
      }
    },
    CheckMContact(s, e) {
      // console.log(this.myObj.contact_mother);
      if (e.valid) {
        this.mphoneerror = false;
        this.myObj.contact_mother = e.number;
        // console.log(this.myObj.contact_mother);
      } else {
        this.mphoneerror = true;
      }
    },
    async updateStudent() {
      this.CheckName();
      this.CheckFName();
      this.CheckGender();
      this.CheckDob();
      this.CheckReligion();
      this.CheckDate();
      this.CheckLast();
      this.CheckPlace();
      this.CheckTongue();
      this.CheckNationality();
      this.CheckAddress();
      this.CheckOccup();
      this.CheckCnic();
      this.CheckEmail();
      this.CheckEmp();
      this.CheckOffice();
      this.CheckRec();
      if (!this.myObj.contact_father) this.fphoneerror = true;
      if (!this.myObj.contact_mother) this.mphoneerror = true;
      if (
        this.CheckName() == false ||
        this.CheckFName() == false ||
        this.CheckGender() == false ||
        this.CheckDob() == false ||
        this.CheckReligion() == false ||
        this.CheckDate() == false ||
        this.CheckLast() == false ||
        this.CheckPlace() == false ||
        this.CheckTongue() == false ||
        this.CheckNationality() == false ||
        this.CheckAddress() == false ||
        this.CheckOccup() == false ||
        this.CheckCnic() == false ||
        this.CheckEmail() == false ||
        this.CheckEmp() == false ||
        this.CheckOffice() == false ||
        this.CheckRec() == false ||
        this.fphoneerror ||
        this.mphoneerror
      ) {
        this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        this.request = true;
        this.myObj.receiptNo = parseInt(this.myObj.receiptNo);

        var status = await this.put({
          url:
            this.$store.state.domain +
            "students/" +
            this.sID +
            "?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Student updated successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          var url =
            `https://${this.reportDomain}.myskool.app/Certificate/Show?type=admission-form&dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.myObj.campusID +
            "&sID=" +
            this.sID +
            "&cls=0&sec=0";
          window.open(url, "_blank");

          this.$router.go(-1);
        }
      }
    },
    async LoadStudent() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Students/GetSelected?id=" +
          this.sID +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("obj:", this.myObj);

      this.dataLoading = false;

      setTimeout(() => {
        this.mphoneerror = false;
        this.fphoneerror = false;
      }, 300);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style></style>
